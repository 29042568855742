import type { YearQualifiedElement } from './investor.types';
import { scrollToId } from '../../lib/scrolling.lib';

export function dataYear(element: YearQualifiedElement): string {
    return element.dataset.year;
}

export function handleGeneralMeetingDownloadsAnchor(): void {
    const anchor = document.URL.split('#');

    if (anchor[1] && anchor[1] === 'general-meeting-downloads') {
        const mobileElement = document.querySelector<HTMLElement>('.gm-mobile');
        const desktopElement = document.querySelector<HTMLElement>('.gm-desktop');

        if (mobileElement && getComputedStyle(mobileElement).display === 'block') {
            scrollToId('gm-download-mobile').then();
        }

        if (desktopElement && getComputedStyle(desktopElement).display === 'block') {
            scrollToId('gm-download-desktop').then();
        }
    }
}
