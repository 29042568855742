export const { fontFamily: regularFontFamily } = getComputedStyle(document.body);

export const colors = {
    transparent: 'transparent',

    black: '#000000',
    white: '#ffffff',
    red: '#dd042b',

    gray100: '#f4f4f4',
    gray200: '#e4e4e4',
    gray300: '#e5e5e5',
    gray400: '#aaaaaa',
    gray500: '#848484',
    gray600: '#666666',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#272727',

    productsRow0Background: '#f8f8f8',
    productsRow2Background: '#f3f1f3',
};
