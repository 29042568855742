import { NEVER } from 'rxjs';
import { toggleClasses } from 'lambda-dom';
import {
    WebcastEventSource,
    WebcastInteractive,
    WebcastYearSelection,
    YearQualifiedElement,
} from '../../investor.types';
import { dataYear } from '../../investor.lib';

export class PublicationsInteractive implements WebcastInteractive {

    public readonly selections$ = NEVER;
    public readonly source = WebcastEventSource.NEVER;
    private readonly baseYear: number = new Date().getFullYear() - 6;

    constructor(private readonly elements: YearQualifiedElement[]) {
        const parentElement = document.querySelector('.webcasts-publications');

        if (parentElement && parentElement instanceof HTMLElement && parentElement.dataset.baseYear) {
            this.baseYear = parseInt(parentElement.dataset.baseYear);
        }
    }

    reflect(selection: WebcastYearSelection) {
        if (selection.year === 'archive') {
            this.elements.forEach((element) => toggleClasses('hide')(element, parseInt(dataYear(element)) > this.baseYear));

            return;
        }

        if (! selection.year.includes(String(this.baseYear))) {
            this.elements.forEach((element) => toggleClasses('hide')(element, dataYear(element) !== selection.year));

            return;
        }

        this.elements.forEach((element) => toggleClasses('hide')(element, parseInt(dataYear(element)) > this.baseYear));
    }
}
