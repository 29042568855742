import type { Observable } from 'rxjs';
import type { DatasetElement } from '../../types/common.types';

export interface YearlySharePrice {
    year: string;
    price: string; // double formatted as string
}

export type YearQualifiedElement = DatasetElement<{ year: string }>;

export enum WebcastEventSource {
    Carousel = 'carousel',
    YearTabs = 'selector',
    Dropdown = 'dropdown',
    Manual = 'manual',
    NEVER = 'NEVER',
}

export interface WebcastYearSelection {
    year: string;
    source: WebcastEventSource;
}

export interface WebcastInteractive {
    readonly selections$: Observable<WebcastYearSelection>
    readonly source: WebcastEventSource

    reflect(selection: WebcastYearSelection): void
}
