import { queryAll, touchElement } from 'lambda-dom';
import { construct, pluck } from 'ramda';
import { merge, NEVER } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { YearTabsInteractive } from './interactives/year-tabs.interactive';
import { WebcastEventSource, WebcastInteractive, WebcastYearSelection, YearQualifiedElement } from '../investor.types';
import { PublicationsInteractive } from './interactives/publications.interactive';
import { SwiperInteractive } from './interactives/swiper.interactive';
import { DropdownInteractive } from './interactives/dropdown.interactive';
import { noop } from '../../../lib/common.lib';
import { dataYear } from '../investor.lib';

const noopInteractive = (): WebcastInteractive => ({
    selections$: NEVER,
    source: WebcastEventSource.NEVER,
    reflect: noop,
});

const yearTabs = queryAll<YearQualifiedElement>('.webcasts--year-selector--year[data-year]');
const publications = queryAll<YearQualifiedElement>('.publications--list .publication[data-year]');

const interactives = [
    new YearTabsInteractive(yearTabs),
    new PublicationsInteractive(publications),
    touchElement('div.webcasts--slider', construct(SwiperInteractive)) || noopInteractive(),
    touchElement('select.publications--year-selector', construct(DropdownInteractive)) || noopInteractive(),
];

merge(...pluck('selections$', interactives)).pipe(
    startWith(<WebcastYearSelection>{
        year: dataYear(yearTabs[0]),
        source: WebcastEventSource.Manual,
    }),
).subscribe((selection) => interactives.forEach((interactive) => interactive.reflect(selection)));
