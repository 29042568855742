import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebcastEventSource, WebcastInteractive, WebcastYearSelection } from '../../investor.types';

export class DropdownInteractive implements WebcastInteractive {

    public readonly selections$: Observable<WebcastYearSelection>;
    public readonly source = WebcastEventSource.Dropdown;

    constructor(private readonly element: HTMLSelectElement) {
        this.selections$ = fromEvent(element, 'change').pipe(
            map(() => ({
                year: element.value,
                source: this.source,
            })),
        );
    }

    reflect(selection: WebcastYearSelection) {
        this.element.value = String(selection.year);
    }
}
